<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <!-- tombol  -->
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Tambah Manual Book"
          @click="
            () => {
              formState = {}
              formState.type = 1
              modal1Visible = true
              formState.title_state = 'Tambah Manual Book'
              formState.state = 'new'
              formState.readOnly = false
            }
          "
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <!-- tabel -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <div class="table-responsive">
              <md-table
                :columns="columns"
                :data-source="data"
                row-key="id"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #action="{record}">
                  <div class="d-flex align-items-center ">
                    <a-tooltip title="Baca Manual Book">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="readManualBook(record)">
                        <small>
                          <i class="fe fe-book-open" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Edit Manual Book">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="editManualBook(record.id)">
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Lihat Manual Book">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="lihatManualBook(record.id)">
                        <small>
                          <i class="fe fe-eye" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Hapus data">
                      <a href="javascript: void(0);" class="btn btn-sm mx-1" @click="hapusManualBook(record.id)">
                        <small>
                          <i class="fe fe-trash" style="color: red;" />
                        </small>
                      </a>
                    </a-tooltip>
                  </div>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal1Visible = false"
    >
      <template #footer>
        <a-button
          :loading="isSubmit"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>
      <m-modal ref="modalRef"></m-modal>
    </a-modal>
    <a-modal
      v-model:visible="modal2Visible"
      style="top: 20px"
      :title="modalTitle"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
      @ok="modal2Visible = false"
    >
      <template #footer>
        <a-button key="back" type="primary" @click="modal2Visible = false">Ok</a-button>
      </template>
      <div class="text-center">
        <iframe :src="manualBookUrl" width="100%" height="620px"></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, computed, createVNode } from 'vue'
import apiClient from '@/services/axios'
import MModal from './modal'

import { useRoute, useRouter } from 'vue-router'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'

import acolumns from './columns'

export default {
  components: {
    MModal,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const modalTitle = ref('')
    const manualBookUrl = ref('')
    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modalRef = ref('')
    const formState = ref({
      type: 1,
    })
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    columns.value = acolumns

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const isSubmit = ref(false)
    const errorMessage = ref(null)
    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/manual-book', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }
    const lihatManualBook = id => {
      apiClient
        .get(`/api/manual-book/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat Manual Book'
          formState.value.readOnly = true
        })
        .catch(error => console.error({ failure: error }))
    }
    const editManualBook = id => {
      apiClient
        .get(`/api/manual-book/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit Manual Book'
          formState.value.state = 'edit'
          formState.value.readOnly = false
        })
        .catch(error => console.error({ failure: error }))
    }

    const search = () => {
      fetchData()
    }

    provide('formState', formState)

    const state = reactive({
      loading: false,
      isFetching: false,
    })

    const readManualBook = record => {
      modal2Visible.value = true
      modalTitle.value = record.title
      manualBookUrl.value = record.file
    }

    const hapusManualBook = id => {
      Modal.confirm({
        title: 'Konfirmasi Hapus Manual Book',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Apakah anda yakin menghapus data ini?',

        onOk() {
          apiClient
            .delete(`/api/manual-book/${id}`)
            .then(response => {
              const data = response.data
              console.log(data)
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(error => {
              console.log(error.response.data)
              if (error.response && error.response.data) {
                message.error(error.response.data[0].message)
              } else {
                message.error('Gagal menghapus!')
              }
            })
            .finally(() => {})
        },

        onCancel() {
          console.log('Cancel')
        },
      })
    }

    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'new') {
        isSubmit.value = true
        const form_data = new FormData()
        for (var key in formState.value) {
          if (key === 'active') {
            form_data.append(key, formState.value[key] ? 1 : 0)
          } else {
            form_data.append(key, formState.value[key])
          }
        }
        // TODO: validasi
        apiClient.post('/api/manual-book', form_data)
                 .then(({ data }) => {
                  modal1Visible.value = false
                  isSubmit.value = false
                  fetchData()
                  message.success('Berhasil ditambah')
                })
                .catch(error => {
                  console.log(error.response.data)
                  if (error.response && error.response.data) {
                    message.error(error.response.data[0].message)
                  } else {
                    message.error('Gagal menyimpan!')
                  }
                })
                .finally(() => (isSubmit.value = false))
      } else if (formState.value.state === 'edit') {
        isSubmit.value = true
        const form_data = new FormData()
        for (var key in formState.value) {
          if (key === 'active') {
            form_data.append(key, formState.value[key] ? 1 : 0)
          } else {
            form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
          }
        }
        // TODO: validasi
        apiClient.post('/api/manual-book/' + formState.value.id + '?_method=PUT', form_data)
                 .then(({ data }) => {
                  modal1Visible.value = false
                  isSubmit.value = false
                  message.success('Berhasil diupdate')
                })
                .catch(error => {
                  console.log(error.response.data)
                  if (error.response && error.response.data) {
                    message.error(error.response.data[0].message)
                  } else {
                    message.error('Gagal mengupdate!')
                  }
                })
                .finally(() => (isSubmit.value = false))
      }
    }

    return {
      route,
      router,
      modalTitle,
      modal1Visible,
      modal2Visible,
      searchInput,
      data,
      columns,

      meta,
      handleTableChange,
      startRow,
      formState,
      lihatManualBook,
      editManualBook,
      search,
      state,
      ...toRefs(state),
      submit,
      hapusManualBook,
      readManualBook,
      manualBookUrl,
      isSubmit,
      fetchData,
      modalRef,
      errorMessage,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
