const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
  },
  {
    title: 'Aksi',
    slots: { customRender: 'action' },
  },
]

export default acolumns
